exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mezinarodni-dny-2024-tsx": () => import("./../../../src/pages/mezinarodni-dny-2024.tsx" /* webpackChunkName: "component---src-pages-mezinarodni-dny-2024-tsx" */),
  "component---src-pages-psi-svatky-2024-tsx": () => import("./../../../src/pages/psi-svatky-2024.tsx" /* webpackChunkName: "component---src-pages-psi-svatky-2024-tsx" */),
  "component---src-pages-seznam-jmen-tsx": () => import("./../../../src/pages/seznam-jmen.tsx" /* webpackChunkName: "component---src-pages-seznam-jmen-tsx" */),
  "component---src-pages-seznam-mezinarodnich-dnu-2024-tsx": () => import("./../../../src/pages/seznam-mezinarodnich-dnu-2024.tsx" /* webpackChunkName: "component---src-pages-seznam-mezinarodnich-dnu-2024-tsx" */),
  "component---src-pages-seznam-psich-jmen-tsx": () => import("./../../../src/pages/seznam-psich-jmen.tsx" /* webpackChunkName: "component---src-pages-seznam-psich-jmen-tsx" */),
  "component---src-pages-statni-svatky-2024-tsx": () => import("./../../../src/pages/statni-svatky-2024.tsx" /* webpackChunkName: "component---src-pages-statni-svatky-2024-tsx" */)
}

